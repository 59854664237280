body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel_image{
  max-width: 1650px;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: 1.5s;
}

.carouselImageStyle{
  height: 100%;
  position: relative;
}

.loader{
opacity: 1;
}

.card2 {
  padding: 1rem 1.2rem;
  border: 1px solid #222224;
  box-shadow: 0px 0px 5px #1e1e1e;
}

.card2:hover{
  background-color: #1d2528;
  box-shadow: 0px 0px 25px #222;;
}

.listDates{
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
}

.container_item_principal_menu{
  padding-top: 4%;
  padding-bottom: 4%;
}

.container_item_secondary_menu{
  padding-top: 5%;
  padding-bottom: 5%;
}

.link_login_formbuy{
  color: white;
  font-size: 18px;
}

.backgroud_login1{
  position: absolute;
  top: 0;
  width: 100%;
  height: 52%;
  background: linear-gradient(269.26deg,#707a7c 6.59%,#232634 94.04%);
  -webkit-clip-path: polygon(0 0,100% 0,100% 76%,0 100%);
  clip-path: polygon(0 0,100% 0,100% 76%,0 100%);
  z-index: -1;
}

.backgroud_login2{
  position: absolute;
  top: 38%;
  width: 100%;
  height: 62%;
  background: radial-gradient(64.65% 109.1% at 55% -20.93%,#eceef1 15.1%,#989b9f 100%);
  z-index: -2;
}

.paymentmethod_container{
  padding-left: 40px;
  padding-right: 40px;
  height: 500;
  background-color: 'white';
  position:'absolute'
}

.wrap{
  color: rgb(176, 172, 172);
  box-shadow: 0px 0px 25px #222;
  backdrop-filter: blur(10px);
}

.modal_container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 1050px;
  min-width: 480px;
  min-height: 700px;
  background-color: #363434;
  box-shadow: 0px 0px 10px #222;
}

.button_close_modal_buy{
  height: 40px;
}

.container_cancel_time{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 1020px;
  min-width: 480px;
  min-height: 700px;
  background: white;
}

.container_summary_buy{
  width: 280px;
  padding: 2%;
  margin-bottom: 2%;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 0px 10px #222;;
}

.container_personalInformation{
  max-width: 1100px;
  min-width: 390px;
  margin: auto;
}

.general_button_userprofile{
  padding: 10px;
  border: 0.5px solid;
  box-shadow: 0px 0px 5px #020B31;
}

.container_table_personalInformation{
  max-width: 1100px;
  min-width: 390px;
  height: 200px;
  border-Radius: 10px;
  box-shadow: 0px 0px 6px #020B31;
}

.container_form_update{
  width: 100%;
  padding-top: 50px;
  padding-left: 2%;
  padding-right: 2%;
  margin: auto;
  border: 1px solid black;
  box-shadow: 0px 0px 10px #222;;
}

.title_form_update{
  margin: 30px
}

.container_update_client{
  margin: 10px;
}

.navbar{
  box-shadow: 0px 0px 10px #222;
  background-color: #020B31;
}

.sidebar{
  min-Width: 350;
  padding-Top: 4%;
  background-color: #3D3B3B;
}

.button_Sidebar_User:hover{
  background: #606B78;
}

.container_button_close_sidebar{
  margin-Top: 300px;
}

.general_button_userprofile{
  padding: 10px;
  border: 0.5px solid;
  box-shadow: 0px 0px 5px #020B31;
}

.container_table_history{
  border: 1px solid;
  max-Width: 1200px;
  min-Height: 350px;
  background: '#E6E4F1';
  border-Radius: 10px;
  box-shadow: 0px 0px 6px #020B31;
}

.circular_progress{
  width: 100%;
  height: 200px;
  transition-duration: 4s;
}

@media only screen and (max-width: 1700px) {
  img {
    width: 100%;
  }
}

@media only screen and (max-width: 1700px){
  .googleMap {
    width: 100%;
  }
}

.container_filter_section{
  background: #272727;
  padding: 10px 0px 10px 0px;
  margin: 20px 0px 20px 0px
}

.container_auth_sms{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 350px;
  min-width: 300px;
  min-height: 200px;
  background-color: #363434;
  box-shadow: 0px 0px 5px #222;
}

@media screen and (max-width: 1000px) {
  .sidebar{
    display: none;
  }
  
}